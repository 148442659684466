@import '@spotahome/ui-library/lib/scss/base.module';

$SPOTAHOME_HEADER_INDEX: 10;
$SPOTAHOME_HEADER_HEIGHT: 60px;
$EXPOSED_FILTERS_INDEX: $SPOTAHOME_HEADER_INDEX - 1; // ensures logged user links will never be hidden
$SEARCH_MAP_INDEX: $EXPOSED_FILTERS_INDEX - 1;
$SEARCH_FULL_FILTERS_INDEX: $SPOTAHOME_HEADER_INDEX + 1;
$SEARCH_FULL_FILTERS_BUTTONS_INDEX: $SEARCH_FULL_FILTERS_INDEX + 1;

@mixin flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin general-hover {
  transition: box-shadow 0.1s ease-out, transform 0.05s ease-out,
    opacity 0.1s ease-out;

  &:hover,
  &:focus {
    opacity: 0.85;
    transform: translate(0px, -1px);
    box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.2);
  }

  &:active {
    box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.2);
    transform: translate(0px, 2px);
  }
}

@mixin disable-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin visual-hide {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin card-box($color: $translucid) {
  border-radius: 0 0 4px 4px;
  transition: box-shadow 0.25s ease-out, transform 0.25s ease-out,
    opacity 0.25s ease-out, border-top 0.25s ease-out;
  box-shadow: 0 2px 0 0 rgba($color, 0.5);
}

@mixin clearfix {
  // from http://nicolasgallagher.com/micro-clearfix-hack/
  //
  //  For modern browsers
  //  1. The space content is one way to avoid an Opera bug when the
  //     contenteditable attribute is included anywhere else in the document.
  //     Otherwise it causes space to appear at the top and bottom of elements
  //     that are clearfixed.
  //  2. The use of `table` rather than `block` is only necessary if using
  //     `:before` to contain the top-margins of child elements.
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }

  // For IE 6/7 only
  // Include this rule to trigger hasLayout and contain floats.
  & {
    *zoom: 1;
  }
}

.clearfix {
  @include clearfix;
}
